// WRITE ARTICLE
export const linkAccountToContact = {
  order: 6,
  name: "Link your account to a contact",
  subcategory: "Manage your account",
  markdown: `# Link your account to a contact

  {% callout type="tip" marginStyles="mt-4" %}
  **Tip:** New to Tower Hunt? Read our {% inlineRouterLink articleId="getting-started-for-new-tower-hunt-users" %}guide for new users{% /inlineRouterLink %} and learn more about our {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay-as-you-go approach{% /inlineRouterLink %}.
  {% /callout %}

  ## From a datapoint

  ## From a contact detail page

  {% tablessContainer %}
  1. Get started using Tower Hunt!
  {% /tablessContainer %}`,
};
